import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Contact } from "../../services/serv";
import { SnackbarCloseReason, Snackbar, Alert, FormControl, MenuItem, Select, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CalendlyBooking from "./calendarinterface";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { PopupButton, PopupModal, PopupWidget } from "react-calendly";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const FormFreeDemo: React.FunctionComponent<{}> = (props) => {
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState<any>(false);
    const [color, setColor] = useState<any>();
    const [message, setMessage] = useState<any>();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [NumberEmployees, setNumberEmployees] = useState("");

    const [error, setError] = useState('');
    const [formError, setFormError] = useState({
        name: false,
        email: false,
        companyName: false,
        phone: false,
        NumberEmployees: false
    });
    const [openCalend, setOpenCalend] = useState(false);

    const handleCloseCalend = () => {
        setOpenCalend(false);
    };

    const validateEmail = (email: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormError({
            name: false,
            email: false,
            companyName: false,
            phone: false,
            NumberEmployees: false
        });
        let valid = true;
        if (!name) {
            setFormError((prevState) => ({ ...prevState, name: true }));
            valid = false;
        }
        if (!email) {
            setFormError((prevState) => ({ ...prevState, email: true }));
            valid = false;
        } else if (!validateEmail(email)) {
            setError('Invalid email address');
            valid = false;
        }
        if (!companyName) {
            setFormError((prevState) => ({ ...prevState, companyName: true }));
            valid = false;
        }
        if (!phone) {
            setFormError((prevState) => ({ ...prevState, phone: true }));
            valid = false;
        }
        if (!NumberEmployees) {
            setFormError((prevState) => ({ ...prevState, NumberEmployees: true }));
            valid = false;
        }
        if (!valid) {
            return;
        }
        else {
            setError('');
            setOpenCalend(true);
            // Contact({ name: name, company_email: email, message: companyName, phone: phone, NumberEmployees: NumberEmployees }).then((resp) => {
            //     setColor("success");
            //     setOpen(true);
            //     setMessage("Congratulations. Your message has been sent successfully")
            // }).catch((error) => {
            //     setColor("error");
            //     setOpen(true);
            //     setMessage("Error, please retry. Your message has not been sent")
            // })
        }
        setError('');


    };
    return (<>
        <div className="section-contact form-free">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6 form-group mb-3">
                            <label htmlFor="name" className="form-label">{t("Name*")}</label>
                            <input
                                type="text" id="name"
                                className={`form-control-input height-48 ${formError.name ? 'error-border' : ''}`}

                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            {formError.name && <p className="error">{t("Name is required")}</p>}
                        </div>
                        <div className="col-6 form-group mb-3">
                            <label htmlFor="companyName" className="form-label">{t("Company Name*")}</label>
                            <input
                                type="text" id="companyName"
                                className={`form-control-input height-48 ${formError.companyName ? 'error-border' : ''}`}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                            {formError.companyName && <p className="error">{t("Company Name is required")}</p>}
                        </div>
                        <div className="col-md-6 form-group  mb-3">
                            <label htmlFor="Phone Number" className="form-label">{t("Phone Number")}*</label>
                            <input type="phone" id="Phone Number" className={`form-control-input height-48 ${formError.phone ? 'error-border' : ''}`}
                                value={phone} onChange={(e) => setPhone(e.target.value)}
                            />
                            {formError.phone && <p className="error">{t("Phone number is required")}</p>}
                        </div>
                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Number of employees" className="form-label">{t("Number of employees*")}</label>
                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Please Select' }}
                                    value={NumberEmployees}
                                    className={`form-control-input height-48 ${formError.NumberEmployees ? 'error-border' : ''}`}
                                    onChange={(e) => setNumberEmployees(e.target.value)}
                                    sx={{
                                        borderRadius: "40px"
                                    }}
                                >
                                    <MenuItem disabled >{t("Please Select")}</MenuItem>
                                    <MenuItem value={"1"}>1</MenuItem>
                                    <MenuItem value={"2 to 5"}>2 to 5</MenuItem>
                                    <MenuItem value={"6 to 10"}>6 to 10</MenuItem>
                                    <MenuItem value={"11 to 25"}>11 to 25</MenuItem>
                                    <MenuItem value={"26 to 50"}>26 to 50</MenuItem>
                                    <MenuItem value={"51 to 200"}>51 to 200</MenuItem>
                                    <MenuItem value={"201 to 1,000"}>201 to 1,000</MenuItem>
                                    <MenuItem value={"1,001 to 10,000"}>1,001 to 10,000</MenuItem>
                                    <MenuItem value={"10,001 to more"}>10,001 to more</MenuItem>

                                </Select>
                            </FormControl>
                            {formError.name && <p className="error">{t("Number of employees is required")}</p>}
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <label htmlFor="email" className="form-label">{t("Email*")}</label>
                        <input
                            type="email" id="email"
                            className={`form-control-input height-48 ${formError.email ? 'error-border' : ''}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {formError.email && <p className="error">{t("Email is required")}</p>}
                        {error && <p className="error">{error}</p>}
                    </div>
                    <button className="button" onClick={handleSubmit} style={{fontSize:"16px"}}>{t("Get Your Free Demo")}</button>
                </div>
            </div>
        </div>
        {openCalend &&
            <PopupModal
                url="https://calendly.com/hassanfeyd13/30min"
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideLandingPageDetails: true,
                    primaryColor: '573CFF',
                    textColor: '000000',
                }}
                prefill={{
                    email: email,
                    name: name,
                }}
                rootElement={document.getElementById("root") || document.body}
                onModalClose={handleCloseCalend}
                open={openCalend}
            />}

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={color}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    </>
    )

}
export default FormFreeDemo;