import axios from "axios";
const Url = process.env.REACT_APP_URl;


export const NewsletterServ = async (email: any) => {
  const apiUrl = Url + "/news-letter";
  const response = await axios.post(apiUrl, { email: email })
  return response.data;
}

export const Contact = async (obj: any) => {
  const apiUrl = Url + "/api/sendcontact";
  const response = await axios.post(apiUrl, obj)
  return response.data;
}