import { Outlet } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";

const Layout: React.FunctionComponent<{}> = (props) => {


    return (
        <div data-bs-spy="scroll" data-bs-target="#navbarExample">
        <Header/>
        <Outlet/>
        <Footer/>
        </div>
    )
    
}
export default Layout;