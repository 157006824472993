import { useTranslation } from "react-i18next";

const Footer: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();
   

   
    return (<>
        <div className="basic-7 marg-2rem">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-xl-12">
                        <div className="padd-basic-7">
                            <h1> {t("We're Here to Support Your Success")} </h1>
                            <p>{t("Have questions or want to explore how Coralytics")} </p>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                    <a className="butt-started">
                                        {t("Get Started")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
      
    </>
    )

}
export default Footer;