import { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';


const Header: React.FunctionComponent<{}> = (props) => {

    const [langue, setLangue] = useState<any>(localStorage.getItem('language') ? localStorage.getItem('language') : "en");
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };


    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        setLangue(lng)
        document.body.classList.toggle('rtl', lng === 'ar');
        document.body.classList.toggle('ltr', lng !== 'ar');
    };

    return (
        <>

            <nav id="navbarExample" className="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
                <div className="container">
                    <a className="navbar-brand logo-image" href="/home"><img src="/assets/images/Coralytics trans.webp" alt="alternative" /></a>
                    <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation" onClick={handleToggle}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`navbar-collapse offcanvas-collapse  ${isOpen ? 'open' : ''}`} id="navbarsExampleDefault">
                        <ul className="navbar-nav ms-auto navbar-nav-scroll nav-ar">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" aria-expanded="false" aria-controls="dropdown-menu" data-bs-toggle="dropdown">{t("products")} <i className="fa fa-chevron-down"></i></a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown01">
                                    <li >
                                        <a className="dropdown-item dropdown-height  d-flex align-items-center" href="/AI-Listing-Creation" aria-label="AI Listing Creation">
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="dropdown-item-img"
                                                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                    fill="#746AFF" stroke="none">
                                                    <path d="M1960 4886 c-191 -53 -342 -192 -407 -376 -12 -36 -26 -64 -30 -63
-5 1 -42 14 -83 29 -64 23 -92 27 -185 27 -93 0 -122 -4 -190 -26 -101 -33
-189 -91 -261 -170 -65 -73 -127 -194 -145 -285 -16 -85 -7 -231 20 -306 12
-33 21 -62 21 -66 0 -4 -17 -11 -37 -15 -21 -3 -76 -25 -123 -47 -158 -76
-279 -230 -315 -403 -21 -103 -21 -1087 0 -1190 39 -188 180 -356 356 -425 30
-12 55 -23 57 -24 1 -2 -13 -28 -33 -57 -189 -287 -83 -692 225 -853 98 -51
171 -69 280 -69 109 0 198 20 279 64 55 29 71 30 71 2 0 -9 16 -50 36 -91 69
-144 221 -267 379 -307 76 -19 214 -19 290 0 200 51 362 211 422 415 12 42 16
122 20 348 l5 292 224 0 224 0 0 -163 c0 -206 8 -234 98 -328 l64 -69 -23 -47
c-35 -73 -33 -179 4 -255 66 -134 224 -200 365 -154 62 21 144 92 174 150 29
57 36 167 14 234 -48 144 -225 233 -371 188 l-50 -16 -58 58 c-34 34 -62 71
-68 92 -6 19 -9 96 -7 170 l3 135 274 0 274 0 148 -155 c82 -85 149 -161 149
-168 0 -7 -10 -32 -22 -55 -35 -65 -32 -183 6 -260 52 -108 175 -176 294 -164
192 20 312 203 258 392 -23 78 -106 163 -184 189 -53 18 -145 18 -212 1 -23
-6 -41 9 -174 154 -111 120 -161 166 -199 186 l-51 25 -578 0 -578 0 0 110 0
110 30 0 c104 0 236 58 320 141 102 100 150 230 150 407 l0 72 312 0 311 0
115 -113 115 -114 -18 -53 c-50 -151 35 -330 182 -381 59 -21 170 -16 224 8
60 27 125 92 152 152 33 71 31 189 -5 257 -34 64 -105 128 -165 148 -64 22
-152 20 -215 -4 l-52 -20 -111 110 c-77 76 -127 117 -165 134 l-55 26 -312 0
-313 0 0 165 0 164 482 1 483 0 51 25 c38 19 86 63 199 186 135 146 151 160
174 153 138 -37 242 -12 328 78 81 84 103 232 51 333 -117 224 -421 227 -536
5 -32 -61 -34 -70 -30 -145 3 -57 11 -93 27 -123 l22 -44 -25 -31 c-14 -18
-81 -90 -149 -162 l-124 -130 -277 -3 -277 -3 3 165 3 166 65 65 66 66 39 -17
c57 -24 164 -15 230 19 219 112 218 426 -3 534 -47 23 -70 28 -132 28 -122 0
-213 -56 -272 -168 -33 -61 -33 -194 0 -255 l23 -44 -61 -63 c-93 -98 -100
-118 -100 -322 l0 -173 -130 0 -130 0 0 53 c0 252 -146 455 -367 511 l-48 12
-3 191 -2 191 217 4 c203 4 221 5 263 27 28 14 119 96 237 214 l191 190 57
-18 c189 -59 379 69 392 265 19 268 -296 419 -500 241 -81 -72 -119 -213 -82
-311 9 -25 19 -53 22 -63 4 -14 -32 -57 -153 -179 -87 -88 -177 -173 -198
-189 l-39 -29 -204 0 -203 0 0 195 c0 224 -7 270 -61 382 -70 147 -215 266
-375 308 -70 18 -230 19 -294 1z m255 -141 c164 -43 292 -181 325 -350 7 -41
10 -199 8 -493 l-3 -433 -74 -25 c-85 -29 -183 -93 -200 -131 -16 -35 2 -77
39 -93 32 -13 38 -11 126 48 65 44 113 55 209 50 126 -7 227 -73 282 -187 l28
-56 0 -515 0 -515 -28 -56 c-90 -185 -311 -247 -491 -138 -36 21 -76 39 -90
39 -27 0 -66 -41 -66 -71 0 -31 55 -83 119 -114 l62 -30 -3 -490 -3 -490 -36
-76 c-66 -137 -184 -226 -334 -252 -128 -22 -276 26 -373 121 -60 59 -117 166
-127 242 -6 44 -4 53 25 94 38 56 69 124 70 152 0 32 -36 64 -74 64 -38 0 -54
-15 -87 -85 -50 -105 -181 -207 -300 -234 -310 -72 -602 193 -558 506 30 212
188 366 404 393 79 10 111 27 115 60 8 58 -22 90 -83 90 -72 0 -179 -28 -252
-65 -67 -34 -73 -35 -124 -25 -159 30 -283 135 -338 287 l-23 64 0 554 c0 507
2 558 18 612 49 157 188 277 351 302 l64 10 61 -55 c61 -56 174 -119 233 -130
62 -12 111 78 66 119 -10 8 -38 23 -63 33 -72 28 -149 83 -193 138 -188 238
-111 574 162 705 74 35 79 36 191 36 112 0 117 -1 193 -37 128 -62 216 -167
252 -303 8 -33 15 -76 15 -98 0 -27 7 -45 25 -62 30 -30 54 -31 89 -4 23 18
26 27 26 83 0 118 -57 265 -140 359 -41 46 -42 50 -37 99 9 69 54 162 109 223
111 121 275 171 433 130z m1596 -51 c29 -8 81 -71 89 -110 12 -52 -2 -102 -41
-142 -62 -63 -155 -63 -217 1 -64 66 -63 153 2 216 46 45 93 55 167 35z m75
-965 c86 -86 50 -225 -67 -260 -109 -33 -220 78 -189 190 7 27 25 52 53 76 40
35 45 37 105 33 56 -4 66 -8 98 -39z m794 -198 c110 -58 108 -216 -3 -272 -71
-37 -149 -15 -201 55 -29 38 -29 124 0 163 52 70 133 92 204 54z m-384 -1500
c108 -49 116 -200 14 -268 -87 -59 -217 0 -235 107 -14 83 67 180 150 180 17
0 48 -9 71 -19z m74 -1113 c144 -74 92 -288 -70 -288 -161 0 -211 218 -67 289
54 27 82 26 137 -1z m-814 -228 c45 -28 78 -99 71 -151 -16 -117 -159 -176
-247 -102 -85 71 -79 197 12 253 44 27 120 27 164 0z"/>
                                                    <path d="M1180 3090 c-137 -24 -239 -119 -275 -255 -19 -75 -22 -668 -4 -709
25 -54 109 -54 129 -1 6 15 10 98 10 186 l0 159 195 0 195 0 0 -160 c0 -97 4
-169 11 -184 25 -55 109 -54 130 1 6 18 9 149 7 368 l-3 340 -27 58 c-66 140
-218 222 -368 197z m140 -162 c24 -13 58 -40 74 -61 30 -37 31 -44 34 -148 l4
-109 -196 0 -196 0 0 95 c0 75 4 104 19 133 52 101 164 139 261 90z"/>
                                                    <path d="M1725 3045 c-28 -27 -31 -45 -14 -78 17 -32 28 -37 87 -37 l52 0 0
-349 0 -349 -57 -4 c-43 -4 -61 -10 -75 -28 -23 -29 -23 -62 1 -91 19 -24 22
-24 206 -24 184 0 187 0 206 24 24 29 24 62 1 91 -14 18 -32 24 -75 28 l-57 4
0 349 0 349 53 0 c58 0 69 5 86 37 17 33 14 51 -14 78 -24 25 -27 25 -200 25
-173 0 -176 0 -200 -25z"/>
                                                </g>
                                            </svg>

                                            {t("AI Listing Creation")}
                                        </a>
                                    </li>
                                    <li ><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Generate" aria-label="Generate">
                                        <img className="dropdown-item-img" src="/assets/images/GENERATE 1 PNG 30.30.webp" alt="" />
                                        {t("Generate")}</a></li>
                                    <li ><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Enhance" aria-label="Enhance">
                                        <img className="dropdown-item-img" src="/assets/images/ENHANCE 2 PNG 16.16.webp" alt="" />
                                        {t("Enhance")}</a></li>
                                    <li ><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Understand" aria-label="Understand">
                                        <img className="dropdown-item-img" src="/assets/images/UNDERSTAND 2 PNG 16.16.webp" alt="" />
                                        {t("Understand")}</a></li>
                                    <li ><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Moderate" aria-label="Moderate">
                                        <img className="dropdown-item-img" src="/assets/images/MODERATE 2 PNG 16.16.webp" alt="" />
                                        {t("Moderate")}</a></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" aria-expanded="false" aria-controls="dropdown-menu" data-bs-toggle="dropdown">
                                    {t("solutions")} <i className="fa fa-chevron-down"></i>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown01">
                                    <li><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Real-Estate-Agencies" aria-label="Real Estate Agencies">
                                        <img className="dropdown-item-img" src="/assets/images/REAL ESTATE AGENCIES 2 PNG 16.16.webp" alt="" />
                                        {t("Real Estate Agencies")}</a></li>
                                    <li><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Marketplace" aria-label="MarketPlaces" >
                                        <img className="dropdown-item-img" src="/assets/images/MARKETPLACES 2 PNG 16.16.webp" alt="" />
                                        {t("MarketPlaces")}</a></li>
                                    <li><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Developers" aria-label="Real Estate Developers">
                                        <img className="dropdown-item-img" src="/assets/images/DEVELOPERS 2 PNG 16.16.webp" alt="" />
                                        {t("Real Estate Developers")}</a></li>
                                    <li><a className="dropdown-item dropdown-height d-flex align-items-center" href="/Interior-Designers" aria-label="Interior Designers">

                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="dropdown-item-img"
                                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet">

                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#746AFF" stroke="none">
                                                <path d="M2450 5025 c-30 -9 -73 -28 -95 -42 -22 -14 -233 -175 -470 -358
-236 -182 -447 -344 -467 -359 l-38 -28 0 136 0 136 43 0 c33 0 49 6 65 23 21
22 22 32 22 231 0 206 0 207 -25 231 l-24 25 -510 0 c-358 0 -517 -3 -535 -11
-42 -20 -47 -51 -44 -262 4 -231 3 -229 101 -237 l62 -5 2 -455 c2 -250 -1
-460 -5 -466 -4 -5 -79 -66 -167 -133 -88 -68 -175 -138 -194 -155 -104 -96
-110 -276 -11 -374 53 -54 106 -76 182 -76 l68 -1 0 -1358 0 -1358 25 -24 24
-25 2101 0 2101 0 24 25 25 24 0 1353 0 1353 68 1 c78 1 128 22 184 81 49 51
68 99 68 175 0 79 -31 147 -91 204 -24 23 -209 168 -411 323 l-367 281 52 48
c29 27 153 139 277 249 260 231 302 275 330 349 27 74 27 184 -1 259 -29 77
-104 156 -187 197 -60 30 -75 33 -157 33 -82 0 -96 -3 -158 -33 -64 -32 -84
-52 -325 -320 -141 -158 -277 -308 -302 -335 l-45 -47 -445 341 c-245 188
-469 353 -498 366 -67 32 -175 39 -252 18z m198 -164 c27 -15 863 -649 890
-676 8 -8 -132 -156 -144 -151 -7 3 -187 138 -400 302 -214 164 -401 302 -415
308 -21 8 -33 6 -55 -9 -16 -10 -500 -380 -1076 -822 -576 -442 -1058 -807
-1071 -812 -46 -18 -107 11 -127 59 -18 43 -5 91 33 127 46 43 347 273 358
273 13 0 674 513 729 565 66 62 1058 825 1094 841 47 20 140 17 184 -5z m1905
10 c72 -28 136 -120 137 -197 0 -93 -3 -96 -756 -766 -385 -343 -708 -629
-717 -637 -15 -12 -23 -7 -69 41 -29 30 -64 61 -77 69 l-24 15 194 216 c107
118 263 291 347 384 84 93 288 321 453 507 187 209 315 345 338 357 39 21 132
27 174 11z m-3193 -111 l0 -100 -415 0 -415 0 0 100 0 100 415 0 415 0 0 -100z
m-130 -450 l0 -189 -264 -206 c-145 -113 -267 -205 -270 -205 -3 0 -6 178 -6
395 l0 395 270 0 270 0 0 -190z m1695 -116 c198 -152 361 -280 363 -285 2 -5
-89 -112 -202 -237 l-206 -229 -79 -5 c-63 -4 -93 -11 -144 -36 -158 -78 -221
-184 -307 -519 l-11 -42 -597 -3 -597 -3 -50 -27 c-64 -34 -118 -90 -147 -153
-23 -49 -23 -58 -26 -426 l-3 -377 -79 -4 c-67 -3 -83 -7 -102 -26 l-23 -23 0
-515 0 -516 28 -24 c27 -23 35 -24 158 -24 l129 0 0 -149 c0 -144 1 -151 24
-178 l24 -28 178 -3 c293 -5 284 -12 284 203 l0 155 294 0 295 0 3 -155 c4
-214 -14 -200 258 -200 272 0 254 -14 258 200 l4 155 134 0 c130 0 136 1 159
25 l25 24 0 510 c0 280 -3 517 -6 526 -11 27 -60 45 -124 45 l-60 0 0 365 0
364 58 16 c121 33 184 56 222 80 59 38 118 104 150 170 29 58 54 175 48 224
-3 21 37 61 251 252 l254 228 71 -56 c39 -30 216 -168 394 -306 l322 -250 0
-1354 0 -1353 -1990 0 -1990 0 0 1358 0 1357 558 427 c306 235 753 578 992
762 239 184 437 335 440 335 3 0 167 -124 365 -275z m1498 -690 c205 -158 389
-303 409 -322 23 -22 40 -49 44 -69 18 -96 -83 -157 -167 -100 -57 39 -814
628 -817 636 -2 7 144 141 154 141 3 0 172 -129 377 -286z m-1495 -230 c100
-32 160 -108 169 -213 6 -69 -8 -124 -46 -181 -44 -66 -93 -93 -237 -129 -71
-18 -178 -50 -239 -72 -60 -21 -111 -36 -113 -34 -3 2 10 67 27 145 63 277
114 393 195 448 81 54 154 65 244 36z m-621 -607 c-3 -7 -11 -48 -17 -91 -12
-72 -12 -79 7 -104 29 -40 65 -38 178 6 55 22 110 43 123 46 l22 7 0 -339 0
-340 -85 -3 c-114 -5 -126 -17 -133 -128 l-5 -81 -558 0 -559 0 0 78 c-1 47
-6 84 -14 94 -19 26 -60 38 -127 38 l-59 0 0 360 c0 395 0 393 60 444 l32 26
570 0 c467 0 569 -2 565 -13z m-1185 -1233 l3 -256 28 -24 28 -24 655 0 c693
0 701 0 718 45 3 9 6 129 6 266 l0 249 130 0 130 0 0 -405 0 -405 -975 0 -975
0 0 405 0 405 124 0 125 0 3 -256z m1278 -49 l0 -95 -560 0 -560 0 0 95 0 95
560 0 560 0 0 -95z m-1010 -765 l0 -100 -100 0 -100 0 0 100 0 100 100 0 100
0 0 -100z m1100 0 l0 -100 -100 0 -100 0 0 100 0 100 100 0 100 0 0 -100z"/>
                                                <path d="M1883 3797 c-10 -13 -40 -64 -67 -114 -50 -89 -51 -90 -130 -133
-120 -64 -146 -85 -146 -121 0 -49 14 -63 123 -124 l103 -57 60 -106 c64 -116
88 -135 141 -116 21 7 42 35 87 116 l58 106 106 58 c81 45 109 66 116 87 19
53 0 77 -115 142 l-107 60 -52 95 c-29 52 -60 103 -68 113 -23 26 -86 23 -109
-6z m89 -267 c20 -36 41 -57 80 -79 l52 -30 -34 -18 c-44 -23 -90 -69 -113
-113 l-18 -34 -30 52 c-16 28 -40 59 -54 67 -14 8 -37 22 -51 31 l-27 16 51
30 c37 21 60 43 79 79 16 27 30 49 33 49 3 0 17 -23 32 -50z"/>
                                                <path d="M3516 2831 c-23 -26 -356 -848 -356 -881 0 -15 10 -36 23 -48 21 -20
35 -22 192 -24 l170 -3 0 -540 0 -540 -72 -5 c-128 -9 -163 -56 -163 -220 0
-121 14 -161 69 -197 34 -23 36 -23 406 -23 370 0 372 0 406 23 55 36 69 76
69 197 0 121 -14 161 -69 197 -26 18 -48 23 -97 23 l-64 0 0 545 0 545 50 0
50 0 0 -340 c0 -327 1 -341 20 -360 27 -27 83 -27 110 2 19 21 20 33 20 360
l0 338 36 0 c46 0 69 13 83 48 12 28 0 59 -163 463 -97 239 -184 440 -193 447
-12 9 -82 12 -263 12 -228 0 -248 -1 -264 -19z m562 -465 c73 -180 131 -330
129 -332 -2 -2 -196 -3 -430 -2 l-426 3 128 315 c70 173 130 323 134 333 6 16
21 17 169 15 l163 -3 133 -329z m-208 -1031 l0 -545 -85 0 -85 0 0 545 0 545
85 0 85 0 0 -545z m230 -766 l0 -69 -315 0 -315 0 0 69 0 70 315 0 315 -1 0
-69z"/>
                                                <path d="M1762 2501 c-15 -15 -44 -64 -65 -107 l-39 -80 -96 -49 c-110 -57
-133 -84 -112 -135 9 -21 38 -44 110 -85 l98 -55 51 -94 c39 -72 57 -96 78
-104 53 -17 78 2 139 105 35 60 67 101 85 111 134 76 164 98 172 129 14 48 -9
74 -113 130 l-91 48 -50 92 c-70 129 -113 153 -167 94z m78 -250 c13 -33 43
-62 84 -83 l28 -15 -44 -26 c-25 -14 -52 -41 -63 -61 -11 -20 -23 -36 -27 -36
-4 0 -16 17 -27 38 -11 22 -35 46 -60 60 -23 13 -41 25 -41 27 0 3 18 14 40
25 24 12 48 36 62 60 13 22 26 40 29 40 4 0 12 -13 19 -29z"/>
                                            </g>
                                        </svg>

                                        {t("Interior Designers")}</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/About-Us">{t("About us")}</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="/ContactUs">{t("contact")}</a>
                            </li>
                        </ul>
                        <div className="navbar-nav ms-auto navbar-nav-scroll nav-log">
                            <div className="nav-item">
                                <a className="nav-link" href="#">{t('login')}</a>
                            </div>
                            <div className="nav-item dropdown drop-lang">
                                <a className="nav-link dropdown-toggle drop-lang-us" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg width="16" height="17" style={{ marginRight: "10px" }} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4_241)">
                                            <g clipPath="url(#clip1_4_241)">
                                                <g clipPath="url(#clip2_4_241)">
                                                    <mask id="mask0_4_241" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                                                        <path d="M16 0.0400391H0V16.04H16V0.0400391Z" fill="white" />
                                                    </mask>
                                                    <g mask="url(#mask0_4_241)">
                                                        <path d="M1.33301 8.03972H14.6663M1.33301 8.03972C1.33301 11.7216 4.31778 14.7064 7.99967 14.7064M1.33301 8.03972C1.33301 4.35782 4.31778 1.37305 7.99967 1.37305M14.6663 8.03972C14.6663 11.7216 11.6816 14.7064 7.99967 14.7064M14.6663 8.03972C14.6663 4.35782 11.6816 1.37305 7.99967 1.37305M7.99967 14.7064C9.66719 12.8808 10.6148 10.5117 10.6663 8.03972C10.6148 5.56774 9.66719 3.19862 7.99967 1.37305M7.99967 14.7064C6.33215 12.8808 5.38451 10.5117 5.33301 8.03972C5.38451 5.56774 6.33215 3.19862 7.99967 1.37305" stroke="#746AFF" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4_241">
                                                <rect width="16" height="16" fill="white" transform="translate(0 0.0400391)" />
                                            </clipPath>
                                            <clipPath id="clip1_4_241">
                                                <rect width="16" height="16" fill="white" transform="translate(0 0.0400391)" />
                                            </clipPath>
                                            <clipPath id="clip2_4_241">
                                                <rect width="16" height="16" fill="white" transform="translate(0 0.0400391)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {langue.toUpperCase()}
                                </a>

                                <ul className="dropdown-menu drop-lang-width" aria-labelledby="dropdown01">
                                    <li><a className="dropdown-item" onClick={() => changeLanguage('en')}>EN</a></li>
                                    <li><div className="dropdown-divider"></div></li>
                                    <li><a className="dropdown-item" onClick={() => changeLanguage('ar')}>AR</a></li>
                                </ul>
                            </div>
                            {/* <div className="nav-item dropdown drop-lang">
                                <a className="nav-link dropdown-toggle drop-lang-us" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">
                                    {langue == "en" ? <img width="20" height="20" src="/assets/images/united-kingdom.webp" /> : <img width="20" height="20" src="/assets/images/flag.webp" />}
                                    <i className="fa fa-chevron-down"></i>
                                </a>
                                <ul className="dropdown-menu drop-lang-width " aria-labelledby="dropdown01">
                                    <li>
                                        <a className="dropdown-item drop-lang-width d-flex justify-content-center" onClick={() => changeLanguage('en')}>
                                            <img width="26" height="26" src="/assets/images/united-kingdom.webp" />
                                        </a>
                                    </li>
                                    <li><div className="dropdown-divider"></div></li>
                                    <li><a className="dropdown-item drop-lang-width d-flex justify-content-center" onClick={() => changeLanguage('ar')} >
                                        <img width="30" height="30" src="/assets/images/flag.webp" />
                                    </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>

        </>
    )

}
export default Header;