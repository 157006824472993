import { Route, Routes } from 'react-router-dom';
import Layout from '../component/layout/layout';
import Home from '../component/home/home';
import { useEffect } from 'react';
import i18n from '../i18n';
import AIListingCreation from '../component/AI Listing/AI-Listing-creation';
import RealEstate from '../component/Real Estate Agencies/real-estate';
import Generate from '../component/Generate/Generate';
import Enhance from '../component/Enhance/Enhance';
import Understand from '../component/Understand/Understand';
import Marketplace from '../component/Marketplace/Marketplace';
import InteriorDesigners from '../component/Interior Designers/Interior Designers';
import ContactUs from '../component/Contact Us/Contact Us';
import Developers from '../component/Developers/Developers';
import Moderate from '../component/Moderate/moderate';
import Blogs from '../component/blogs/blogs';
import Blog1 from '../component/blogs/Blog1';
import PrivacyPolicy from '../component/Privacy-Policy';
import TermsConditions from '../component/Terms-Conditions';
import AboutUs from '../component/About-Us';
import Blog2 from '../component/blogs/Blog2';
import Blog3 from '../component/blogs/Blog3';
import Blog4 from '../component/blogs/Blog4';
import Blog5 from '../component/blogs/Blog5';
import Blog6 from '../component/blogs/Blog6';
import RealEstateAgencie from '../component/Real Estate Agencies/real-estate-agencies';



const PublicRouter: React.FunctionComponent<{}> = (props) => {

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
            document.body.classList.toggle('rtl', savedLanguage === 'ar');
            document.body.classList.toggle('ltr', savedLanguage !== 'ar');
        }
    }, []);

    return (
        <>
            <Routes>
            <Route path="/Real_Estate_Agent_Landing" element={<RealEstateAgencie />} />
            
                <Route path='/' element={<Layout />} >
                    <Route path="" element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/AI-Listing-Creation" element={<AIListingCreation />} />
                    <Route path="/Real-Estate-Agencies" element={<RealEstate />} />
                    <Route path="/Generate" element={<Generate />} />
                    <Route path="/Enhance" element={<Enhance />} />
                    <Route path="/Understand" element={<Understand />} />
                    <Route path="/Marketplace" element={<Marketplace />} />
                    <Route path="/Interior-Designers" element={<InteriorDesigners />} />
                    <Route path="/ContactUs" element={<ContactUs />} />
                    <Route path="/Developers" element={<Developers />} />
                    <Route path="/Moderate" element={<Moderate />} />
                    <Route path="/Blogs" element={<Blogs />} />
                    <Route path="/Blogs/boosting-real-estate-confidence-battling-fake-listings-with-AI" element={<Blog1 />} />
                    <Route path="/Blogs/how-AI-is-fixing-real-estate-marketplace-flows" element={<Blog2 />} />
                    <Route path="/Blogs/enhancing-the-visuals-with-AI-A-revolutionary-approach-to-real-estate-marketing" element={<Blog3 />} />
                    <Route path="/Blogs/AI-powered-room-staging-A-game-changer-for-real-estate" element={<Blog4 />} />
                    <Route path="/Blogs/the-power-of-listing-videos-boosting-conversions-for-real-estate-agents" element={<Blog5 />} />
                    <Route path="/Blogs/list-properties-quicker-with-AI-property-description" element={<Blog6 />} />
                    <Route path="/Privacy-Notice" element={<PrivacyPolicy />} />
                    <Route path="/Terms-and-Conditions" element={<TermsConditions />} />
                    <Route path="/About-Us" element={<AboutUs/>} />
                </Route>
            </Routes>

        </>
    )
}
export default PublicRouter;